  /* Checkbox */
  .ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 0;
    width: fit-content;

    span {
      cursor: pointer;
    }

    > span {
      padding-left: 15px;
      height      : 16px;

      &:empty {
        float: left;
      }

      &:before, &:after {
        line-height: 18px;
        position: absolute;
      }


      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-color: $neutral-30;
        border: 1px solid $neutral-60;
        top: 2px;
        left: 0;
        border-radius:3px;
      }

      &:after {
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        content: 'check';
        font-family: 'Material Symbols Outlined';
        color: $white;
        font-size: 16px;
        background-color: $secondary;
        line-height: 16px;
        display: none;
        border-radius:3px;
      }

      &:empty {
        padding-left: 0;
        width: 3px;
      }
    }

    input[type='checkbox'] {
      opacity: 0;
      margin: 0;

      &:checked + span:after {
        display: block;
      }
      &[disabled] + &[checked]{
        background-color: $white;
      }
      

      &[disabled] + span {

        &:before {
          background-color: $primary-10;
        }
        &:after {
          background-color: $secondary-25;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2351BFE7' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

        }
      }
    }
  }



  .ckbox-inline {
    display: inline-block;
  }