/* HORIZONTAL WIZARD */
.mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
        .mat-step-header {
            .mat-step-icon {
                height          : 32px;
                width           : 32px;
                background-color: $neutral-50;
                border          : 2px solid $neutral-60;

                .mat-step-icon-content {
                    color: $neutral-50;
                    
                    span {
                        font-weight: 200;
                    }
                }      

                &-selected {
                    background-color: $secondary;
                    border          : none;
                }
            }

            .mat-step-label {
                font-family: 'Poppins', sans-serif;
            }

            .mat-step-icon-state-passed {
                background-color: $secondary-25;
                border          : 2px solid $secondary;

                .mat-step-icon-content {
                    color: $secondary;
                }
            }
        }

        .mat-stepper-horizontal-line {
            border: 1px solid $neutral-60;
        }

    }

    &.completed {
        .mat-horizontal-stepper-header-container {
            .mat-step-header {
                .mat-step-icon {
                    background-color: $success;
                    border          : 2px solid $success;
                    
                    .mat-step-icon-content {
                        color: $white;
                    }
                }
            }

            .mat-stepper-horizontal-line {
                border: 1px solid $success-50 !important;
            }
        }
    }
}


.mat-stepper-label-position-bottom.inverse-label {

    .mat-stepper-horizontal-line {
        margin: 0;
        min-width: 0;
        position: relative;
        top: -40px;
        align-self: flex-end;
    }

    .mat-horizontal-stepper-header {
        flex-direction: column-reverse;

        &:after,
        &:before {
            top: auto;
            bottom: 40px;
        }
        
        &:not( :last-child ):after,
        &:not( :first-child ):before {
            border: 1px solid $neutral-60;
        }

        &:has( .mat-step-icon-state-passed ) + .mat-stepper-horizontal-line,
        &:has( .mat-step-icon-state-edit ):before,
        &:has( .mat-step-icon-state-passed ):not( :last-child ):after,
        &:has( .mat-step-icon-state-passed ):before {
            border: 1px solid $secondary-50;
        }

        &:has( .mat-step-icon-state-edit ) + .mat-stepper-horizontal-line,
        &:has( .mat-step-icon-state-edit ):after,
        &:has( .mat-step-icon-state-edit ) + * + .mat-horizontal-stepper-header:before {
            border: 1px dashed $secondary;
        }

        .mat-step-label {
            padding: 0;
        }
    }

    &.completed {
        .mat-horizontal-stepper-header {
            &:not( :last-child ):after,
            &:not( :first-child ):before {
                border: 1px solid $success-50;
            }
        }
    }

}