/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/variables";

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/dist/css/bootstrap.css';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// leaflet
@import '~leaflet/dist/leaflet.css';
// chartlist
// @import '~chartist/dist/chartist.css';

// animate css
// @import "~animate.css/animate.min.css";
// ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";

// ngx data table
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.scss';
// @import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
// @import '~@swimlane/ngx-datatable/themes/dark.scss';
// @import '~@swimlane/ngx-datatable/assets/icons.css';

// ngx-toastr
@import 'node_modules/ngx-toastr/toastr.css';

// angular-archwizard
@import '~angular-archwizard/styles/archwizard.css';

// quill
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// .owl-carousel
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// angular material
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

// custom-css import
@import './assets/css/icons.css';

@import './assets/css/style.css';
@import './assets/css/style-dark.css';
@import './assets/css/style-transparent.css';

@import './assets/css/skin-modes.css';
@import './assets/css/animate.css';
// @import './assets/plugins/sidebar/sidebar.css';

@import './assets/switcher/css/switcher.css';
@import './assets/switcher/demo.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// font awsome
@import '../node_modules/angular-archwizard/styles/archwizard.css';
.color-menu,
.gradient-menu {
  .side-menu .slide .side-menu__item.active .side-menu__icon {
    color: #fff !important;
  }
}

.fc .fc-button-primary {
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-border) !important;
}

.fc-theme-standard td,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #ededf5 !important;
}


// ng select

.ng-select>.ng-select-container {
  border-color: #ededf5 !important;
  outline: none !important;
  border-radius: 10px;
  height: 45px;
}

.accordion-button {
  outline: none !important;
}

.dark-theme {

  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid #3d4152 !important;
  }

  .ng-select .ng-select-container {
    background-color: #2a2e3f !important;
    border: 1px solid #32394e !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    color: #fff !important;
    background-color: #212332 !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    // border-right: 1px solid #32394e !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option.ng-option-marked,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #fff;
    background-color: #383d52;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #2a2e3f;
    color: #ccc;
  }

  .ng-dropdown-panel {
    background-color: #2a2e3f;
    border: 1px solid #32394e;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    color: rgb(177 187 200);
  }

  pre {
    color: #664dc9;
    background-color: #202231;
  }

  .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #404353;
    padding: 5px 7px;
  }

  .ng-select .ng-select-container,
  .ng-select input {
    color: #eee !important;
    background: #383d52;
    border: none;
    outline: none;
    border-radius: 0.25rem;
  }
}

.btn-close {
  background-image: url(./assets/close.svg) !important;
  height: 0.1rem;
  width: 0.1rem;
}

ngb-alert .btn-close {
  background-image: url(./assets/close.svg) !important;
  height: 0.1rem;
  position: absolute;
  width: 0.1rem;
  background-repeat: no-repeat;
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%);
}

.rtl {
  ngb-alert .btn-close {
    right: inherit !important;
    left: 10px !important;
  }
}

@media (max-width: 767px) {
  .rtl.app.sidenav-toggled .app-sidebar {
    right: 0px !important;
  }
}

// .transparent-theme .main-header {
//   backdrop-filter: blur(0.25rem);
// }

.ngx-slider {
  z-index: 0 !important;
}

.pagination {
  display: flex;
  // flex-wrap: wrap;
}

.transparent-theme {
  .ng-value {
    color: #fff;
  }

  .ng-placeholder {
    color: #fff !important;
    padding-top: 8px;
  }

  .ng-select-container,
  .ng-select.ng-select-opened>.ng-select-container {
    background: transparent !important;
    border-color: #4cb0a0 !important;
  }

  .ng-dropdown-panel {
    background-color: #2da28f;
    border: 1px solid #37c6af;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #38c8b1;
    color: #fff;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #2da28f !important;
    color: #fff;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #fff;
    background-color: $info-10  !important;
  }

  .side-menu__item:hover .side-menu__icon,
  .side-menu__item:hover .side-menu__label,
  .side-menu__item:hover .angle {
    color: #fff !important;
  }

}

@media screen and (max-width: 767.9px) {
  .slide-menu.open {
    display: block;
  }
}

.apexcharts-toolbar,
.apexcharts-tooltip,
.fc .fc-timegrid-body,
.fc .fc-timegrid-axis-chunk>table,
.fc .fc-timegrid-slots,
.fc .fc-timegrid .fc-daygrid-body,
.owl-carousel,
.list-group-item.active,
.main-contact-label::after {
  z-index: 0 !important;
}

.dropdown-menu,
.popover,
#leaflet1,
.tooltip,
.color-picker {
  z-index: 100 !important;
}

.pagination li {
  margin-block: 0.1rem;
}

.form-lg .ng-select-container {
  height: 44px !important;
}

.form-md .ng-select-container {
  height: 38px !important;
}

.dark-theme .table thead th {
  border-bottom: 1px solid #404353 !important;
}

.transparent-theme .table thead th {
  border-bottom: 1px solid rgba(255, 255, 255, .15) !important;
}



// ng-mat-int-tel-input

.country-selector {
  opacity: 1 !important;
  bottom: 8px !important;
}

app-advanced-forms telephone-input input:not(.country-search) {
  bottom: 3px;
  left: 10px;
}

.country-list-button {
  font-size: 0.8rem !important;
  padding: 8px;
}

.mat-menu-content:not(:empty) {
  max-height: 250px;
}

.mat-form-field {
  width: 300px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5rem 0 !important;
}

.error {
  color: red;
}

.dark-theme {
  .mat-menu-panel {
    background-color: #383d52;
    color: #fff;
  }

  input.country-search {
    border-bottom: 1px solid #404353 !important;
    color: #fff;
    background-color: #2a2e3f;
  }

  .mat-hint,
  .country-selector-code {
    color: #eee !important;
  }

  .label-wrapper {
    color: #fff;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #404353;
  }
}

.transparent-theme {
  .mat-menu-panel {
    background-color: var(--transparent-body);
    color: #fff;
  }

  input.country-search {
    border-bottom: 1px solid #4cb0a0 !important;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .mat-hint,
  .country-selector-code {
    color: #eee !important;
  }

  .label-wrapper {
    color: #fff;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #4cb0a0;
  }
}

.transparent-theme .btn-light:not(:disabled):not(.disabled):active:focus,
.transparent-theme .btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: none !important;
}

.rtl.dark-theme .main-nav .nav-link+.nav-link {
  border-left-width: 0px !important;
}

// .dark-theme .btn-close {
//   filter: invert(1);
// }

.transparent-theme .btn-close {
  filter: invert(1);
}

button:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

.transparent-theme {

  aw-wizard-navigation-bar ul.steps-indicator li a .label,
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after {
    color: #fff;
  }

  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid #4cb0a0 !important;
  }

  .mat-select-panel {
    background: var(--transparent-body);
    color: #fff;
  }

  .mat-option {
    color: #eee;
  }
}

.mat-form-field {
  width: 100% !important;
}

.transparent-theme .form-control {
  background-color: inherit !important;
}

.country-selector {
  right: 0 !important;
  left: 0 !important;
}

.transparent-theme .form-control:focus,
.transparent-theme .form-control {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.transparent-theme .form-control::placeholder {
  color: #eee !important;
}

.dark-theme .main-msg-wrapper.left:nth-child(1):after {
  border-right-color: #404353;
}

.rtl .main-msg-wrapper.left:nth-child(1):after {
  border-left-color: #404353;
  border-right-color: transparent !important;
}

.dark-theme,
.transparent-theme {
  .mat-form-field-label {
    color: #eee;
  }
}

// .dark-theme.light-menu.horizontal .horizontal-main .main-sidemenu .side-menu .slide .slide-menu .slide-item {
//   color: inherit !important;
// }

.rtl {

  input,
  textarea {
    direction: rtl;
  }
}

.rtl.dark-theme .app-sidebar {
  border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
}

.dark-theme .app-sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
}

@media (min-width: 768px){
  .horizontal .side-menu .slide .side-menu__item.active {
    border-bottom: 0.2px solid var(--primary-bg-color) !important;
  }
}

.page{
  overflow: hidden;
}
.file-folder{
  .dropdown-toggle::after{
  display: none !important;
}
}


.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
  background-color: var(--primary-bg-color);
}

.twentytwenty-container img{
  position: relative;
}

.horizontal{
  .horizontal-main{
    .ng-scrollbar-wrapper[_ngcontent-ng-c1997982448], .ng-scroll-viewport-wrapper[_ngcontent-ng-c1997982448], .ng-scroll-layer[_ngcontent-ng-c1997982448], .ng-scroll-viewport{
      position: static !important;
      inset: auto;
    }
    .ng-scroll-viewport{
      contain : inherit
    }
  }
}


.switcher-wrapper {
  .demo_changer.active {
    .ng-scroll-viewport-wrapper {
      .color-picker {
        position: absolute !important;
        right: 50px !important;
        left: auto !important;
        top: auto !important;
        z-index: 1005 !important;
        width: 200px !important;
        height: auto;
        border: #ededf5 solid 1px !important;
        cursor: pointer !important;

      }
    }
  }
}

// /* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';

// CUSTOM STYLES //

// dark theme styles
.dark-theme {
  .card {
    color: #fff;
  }
  .apexcharts-gridline {
    stroke: rgba(255,255,255,0.05);
  }
  .apexcharts-datalabels-group text {
    fill: #fff;
  }
  .mat-mdc-table {
    background: #383d52;;
  }
  .mat-mdc-table .mat-mdc-header-cell {
    color: #fff;
  }
  .mat-mdc-cell {
    color: #fff;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #383d52;
  }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #c0c2c7;
  }
  .mat-mdc-paginator {
    color: #fff;
    background-color: #383d51;
  }
  .mat-mdc-icon-button svg {
    fill: #fff;
  }
  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    filter: invert(1);
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(255, 255, 255, .15) !important;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
    border-color: rgba(255, 255, 255, .15) !important;
  }
  .mat-mdc-select-arrow svg {
    fill: #fff;
  }
  .mat-mdc-select-trigger {
    color: #fff;
  }
  div.mat-mdc-select-panel {
    background-color: #2a2e3f;
  }
  .mat-mdc-option .mdc-list-item__primary-text {
    color: #fff;
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: rgba(255, 255, 255, .15);
  }
}
// rtl styles
.rtl {
  .main-sidemenu {
    direction: rtl;
  }
  .main-header-notification,
  .main-header-message,
  .form_holder.sidebar-right1,
  .sidebar.sidebar-right,
  .main-content-body.main-content-body-chat {
    .ng-scrollbar-wrapper {
      direction: rtl;
    }
  }
  @media (max-width: 991px) {
    &.app.sidenav-toggled .app-sidebar {
      right: 0 !important;
      left: inherit !important;
    }
  }
  .breadcrumb-item+.breadcrumb-item::before {
    padding-left: 0;
  }
}
.apexcharts-tooltip.apexcharts-theme-light {
  background-color: $primary !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: $primary !important;
  border-bottom: 1px solid $border !important;
  color: #fff;
}
.apexcharts-tooltip-text {
  color: #fff;
}
.apexcharts-tooltip-text-y-value {
  margin-inline: 5px;
}

// a:hover {
//   color: #fff;
// }
*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

.ng-scrollbar-wrapper>.scrollbar-control {
  justify-content: end !important;
}
.leaflet-container a {
  color: #0078a8 !important;
}
.apexcharts-canvas {
  margin: 0 auto;
}
@media(max-width: 320px) {
  .donut-chart,
  .pie-chart {
    .apexcharts-canvas {
      width: 225px !important;
    }
  }
}

.sidebar.sidebar-right {
  transform: translate(100%, 0);
}
.sidebar.sidebar-right.sidebar-open {
  transform: translate(0%, 0);
  box-shadow: none;
}
.sidebar-animate {
  transition: transform 300ms ease;
}
.sidebar-right {
  right: 0;
}
.dark-theme {
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: #383d52;
  }
  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #fff;
  }
  .mat-step-header .mat-step-icon {
    background-color: #383d52;
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: #404353;
  }
  .accordion-button {
    background-color: #383d51;
    color: #fff;
  }
  .vtimeline:before {
    background-color: #353949;
  }
  .cal-month-view {
    background-color: #33384a;
  }
  .cal-month-view .cal-day-cell.cal-today {
    background-color: #2a2e3f;
  }
  .cal-month-view .cal-cell-row:hover {
    background-color: #2a2e3f;
  }
  .cal-month-view .cal-cell-row .cal-cell:hover, .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #2a2e3f;
  }
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: #404353;
  }
  .cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: #404353;
  }
  .cal-month-view .cal-days {
    border-color: #404353;
  }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #fff;
  }
  .card {
  &.bg-info {
    background-color: $info !important;
  }
  &.bg-warning {
    background-color: $warning !important;
  }
  }
  .ngb-dp-header {
    background-color: #1c1e29;
  }
  .ngb-dp-weekdays {
    background-color: #1c1e29;
  }
  .cal-month-view .cal-open-day-events {
    background-color: #2a2e3f;
  }
  .cal-week-view {
    background-color: #383d52;
    border-top: solid 1px #404353;
  }
  .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment, .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: #2a2e3f;
  }
  .cal-week-view .cal-hour-odd {
    background-color: #383d52;
  }
  .cal-week-view .cal-day-headers {
    border-color: #404353;
  }
  .cal-week-view .cal-time-events {
    border-color: #404353;
  }
  .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #2a2e3f;
  }
  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: #404353;
  }
  .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
      border-right-color: #404353;
  }
}

.transparent-theme {
    [_nghost-ng-c2040221903] {
      color: #717386;
  }
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: var(--transparent-body);
  }
  .mat-step-header .mat-step-label.mat-step-label-active {
    color: #fff;
  }
  .mat-step-header .mat-step-icon {
    background-color: var(--transparent-body);
  }
  .cal-month-view  {
    background-color: var(--transparent-body) !important;
  }
  .cal-week-view {
    background-color: var(--transparent-body) !important;
  }
  .cal-week-view .cal-hour-odd {
    background-color: var(--transparent-body) !important;
  }
  .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: var(--transparent-body) !important;
  }
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #32394e;
}
.nav-link {
  color: #4a4a69;
}
.color-toasts .btn-close {
  filter: invert(1);
}
.toast-header {
  padding: 0.8rem;
}
.mdc-notched-outline {
  height: 75% !important;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-menu-item.mat-mdc-focus-indicator.mdc-list-item.country-list-button .label-wrapper {
  font-size: 13px;
}
.cal-event-actions.ng-star-inserted a {
  color: #fff;
}
.cal-week-view .cal-event {
  color: #fff;
}
.cdk-overlay-container .cdk-overlay-pane {
  max-height: 300px;
  position: absolute !important;
  top: 4px !important;
}
.country-selector[_ngcontent-ng-c4150375230] {
  height: 40px !important;
}
.telephone-input .ng-pristine.ng-invalid {
  flex-wrap: wrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.rtl {
  .fa.fa-chevron-right,
  .fa.fa-chevron-left {
    transform: rotate(180deg);
  }
  .cdk-overlay-pane {
    direction: rtl;
  }
  .ngb-dp-arrow-next .ngb-dp-navigation-chevron {
    transform: rotate(225deg);
  }
  .ngb-dp-navigation-chevron {
    transform: rotate(50deg);
  }

  .cdk-overlay-container .cdk-overlay-pane {
    right: 300px;
  }

}

.dropdown-toggle::after{
  display: none;
}

ngb-toast{

}
.table-danger thead th {
  background: #f34343 !important;
  color: #fff !important;
}
.table-success thead th {
  background: #24d5b8 !important;
  color: #fff !important;
}
.table-warning thead th {
  background: #ffbd5a !important;
  color: #fff !important;
}
.table-info thead th {
  background: #4ec2f0 !important;
  color: #fff !important;
}
.table-secondary thead th {
  background: #f74f75 !important;
  color: #fff !important;
}
.table-primary thead th {
  background: var(--primary-bg-color) !important;
  color: #fff !important;
}
.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody+tbody {
  border-color: #27a59238 !important;
}
.table-primary {
  tbody td {
    background-color: var(--primary02);
  }
  tbody th {
    background-color: var(--primary02);
  }
}
.table-secondary {
  tbody td {
    background-color: rgba(247, 79, 117, 0.22);
  }
  tbody th {
    background-color: rgba(247, 79, 117, 0.22);
  }
}
.is-loading {
  position: relative;
}
.is-loading>.form-control, .is-loading>.form-control-plaintext, .is-loading>.custom-select, .is-loading>textarea[rows="1"].form-control {
  padding-right: calc(1.5em + 0.75rem);
}
.is-loading>.form-control~.spinner-border.spinner-border-sm, .is-loading>.form-control~.spinner-border.spinner-grow-sm, .is-loading>.form-control~.spinner-grow.spinner-border-sm, .is-loading>.form-control~.spinner-grow.spinner-grow-sm, .is-loading>.form-control-plaintext~.spinner-border.spinner-border-sm, .is-loading>.form-control-plaintext~.spinner-border.spinner-grow-sm, .is-loading>.form-control-plaintext~.spinner-grow.spinner-border-sm, .is-loading>.form-control-plaintext~.spinner-grow.spinner-grow-sm, .is-loading>.custom-select~.spinner-border.spinner-border-sm, .is-loading>.custom-select~.spinner-border.spinner-grow-sm, .is-loading>.custom-select~.spinner-grow.spinner-border-sm, .is-loading>.custom-select~.spinner-grow.spinner-grow-sm, .is-loading>textarea[rows="1"].form-control~.spinner-border.spinner-border-sm, .is-loading>textarea[rows="1"].form-control~.spinner-border.spinner-grow-sm, .is-loading>textarea[rows="1"].form-control~.spinner-grow.spinner-border-sm, .is-loading>textarea[rows="1"].form-control~.spinner-grow.spinner-grow-sm {
  top: calc(0.375em + 0.1875rem + 2px);
  border-radius: 50% !important;
}

.is-loading>.form-control~.spinner-border, .is-loading>.form-control~.spinner-grow, .is-loading>.form-control-plaintext~.spinner-border, .is-loading>.form-control-plaintext~.spinner-grow, .is-loading>.custom-select~.spinner-border, .is-loading>.custom-select~.spinner-grow, .is-loading>textarea[rows="1"].form-control~.spinner-border, .is-loading>textarea[rows="1"].form-control~.spinner-grow {
  position: absolute;
  top: 7px;
  right: calc(0.375em + 0.1875rem);
  z-index: 0;
  border-radius: 50% !important;
}

.was-validated .is-loading>.form-control:valid~.spinner-border, .was-validated .is-loading>.form-control:valid~.spinner-grow, .is-loading>.form-control.is-valid~.spinner-border, .is-loading>.form-control.is-valid~.spinner-grow {
  right: calc(1.75rem + 2px + 3px);
}
.was-validated .is-loading>.form-control:invalid~.spinner-border, .was-validated .is-loading>.form-control:invalid~.spinner-grow, .is-loading>.form-control.is-invalid~.spinner-border, .is-loading>.form-control.is-invalid~.spinner-grow {
  right: calc(1.75rem + 2px + 3px);
}
.is-loading .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.is-loading .spinner-border {
  color: var(--primary-bg-color);
}
.is-loading .spinner-grow {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--primary05);
}


.disable-row {
  color: #A8AAB8;
}

.ng-select.ng-select-single .ng-select-container {
  height: 45px;
}

.ng-div-box {
  justify-items: center;
  align-content: center;
  text-align: center;
  color: #A8AAB8;
  border: 1px solid #A8AAB8;
  border-radius: 6px;

}