/* Radio Box */

.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;
}

.rdiobox span {
  padding-left: 22px; /* Ajusta según sea necesario para alinear el texto correctamente */
}

.rdiobox span:before {
  content: '';
  width: 16px;
  height: 16px;
  border: 1px solid $neutral-60;
  border-radius: 50%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: $neutral-30; /* Color de fondo predeterminado del círculo */
}

.rdiobox span:after {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 0;
  left: 0;
  position: absolute;
  display: none; /* Inicialmente oculto */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-size: 50%; /* Ajusta para que el icono se ajuste bien dentro del círculo */
  background-repeat: no-repeat;
  background-position: center;
  background-color: $secondary; /* Este será el color de fondo cuando esté seleccionado */
}

.rdiobox input[type='radio'] {
  opacity: 0;
  margin: 0;
  position: absolute;
  cursor: pointer;
  &[disabled] + span {

    &:before {
      background-color: $primary-10;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2351BFE7' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      background-color: $secondary-25;
    }
  }
}

.rdiobox input[type='radio']:checked + span:after {
  display: block; /* Muestra el :after con el icono cuando está seleccionado */
}
