@import '../util/typography';
/* ###### 3.14 Table ###### */

.table {
  border-radius   : 10px 10px 0 0;
  border-collapse : separate;
  border-spacing  : 0;
  background-color: $white;
  table-layout    : fixed;

  thead {
    th,
    td {
      background-color   : $secondary-10;
      border-bottom-width: 1px;
      border-top-width   : 0;
      border-collapse    : separate;

      @extend .tx-body-1-bold;
      letter-spacing: .5px;
      text-transform: capitalize;
      text-align    : center;
      padding       : 16px;
      color         : $secondary-darker;
    }

    th:first-child,
    td:first-child {
      border-radius: 10px 0 0 0;
    }

    th:last-child,
    td:last-child {
      border-radius: 0 10px 0 0;
    }
  }

  tbody tr {
    background-color: $white-5;

    th {
      @extend .tx-body-1-bold;
      color: $primary;
    }

    td {
      @extend .tx-body-1;
      color: $primary;
    }
    th, td {
      text-align: center;
    }
  }

  th,
  td {
    padding: 20px 16px;
    line-height: 1.462;
    font-size: 16px;
  }

  tbody tr:first-child {
    th,
    td {
      border-top: none;
    }
  }

  tr.disabled {
    pointer-events  : none;
    background-color: $neutral-30;

    th, td {
      color: $neutral-70;
    }
  }
}

.table-striped tbody tr:nth-of-type(4n+2) {
  background-color: $neutral-20;
}

.table-bordered thead {

  th,
  td {
    border-top-width: 1px;
    padding-top     : 11px;
    padding-bottom  : 11px;
  }
}

.table {
  width        : 100%;
  margin-bottom: 1rem;
  color        : $default-color;

  th,
  td {
    padding       : 0.75rem;
    vertical-align: middle;
    border-top    : 1px solid $neutral-60;
  }

  thead th {
    vertical-align: bottom;
  }
}

.table-sm {

  th,
  td {
    padding: 0.3rem;
  }
}

.table-bordered {
  border: 1px solid $neutral-60;

  th,
  td {
    border: 1px solid $neutral-60;
  }

  tr {
    th:not(:first-child),
    td:not(:first-child) {
      border-left: none;
    }
  }
  
  tr:not(:first-child) {
    th,
    td {
      border-top: none;
    }
  }

}

.table-borderless {

  th,
  td,
  thead th,
  tbody+tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $gray-100;
}

.table-hover tbody tr:hover {
  background-color: $bg-hover;
  color           : $default-color;
}

.table-primary {
  background-color: #c6d4ff;

  > {

    th,
    td {
      background-color: #c6d4ff;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #95afff;
  }
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;

  > {

    td,
    th {
      background-color: #adc1ff;
    }
  }
}

.table-secondary {
  background-color: #d9dde5;

  > {

    th,
    td {
      background-color: #d9dde5;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #b9c1ce;
  }
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;

  > {

    td,
    th {
      background-color: #cacfdb;
    }
  }
}

.table-success {
  background-color: #c8e9b8;

  > {

    th,
    td {
      background-color: #c8e9b8;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #99d67b;
  }
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;

  > {

    td,
    th {
      background-color: #b9e3a5;
    }
  }
}

.table-info {
  background-color: #bee5eb;

  > {

    th,
    td {
      background-color: #bee5eb;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #86cfda;
  }
}

.table-hover .table-info:hover {
  background-color: #abdde5;

  > {

    td,
    th {
      background-color: #abdde5;
    }
  }
}

.table-warning {
  background-color: #ffeeba;

  > {

    th,
    td {
      background-color: #ffeeba;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #ffdf7e;
  }
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;

  > {

    td,
    th {
      background-color: #ffe8a1;
    }
  }
}

.table-danger {
  background-color: #f5c6cb;

  > {

    th,
    td {
      background-color: #f5c6cb;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #ed969e;
  }
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;

  > {

    td,
    th {
      background-color: #f1b0b7;
    }
  }
}

.table-light {
  background-color: #fcfcfd;

  > {

    th,
    td {
      background-color: #fcfcfd;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #f9fafb;
  }
}

.table-hover .table-light:hover {
  background-color: #ededf3;

  > {

    td,
    th {
      background-color: #ededf3;
    }
  }
}

.table-dark {
  background-color: #c8ccd3;

  > {

    th,
    td {
      background-color: #c8ccd3;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #99a0ae;
  }
}

.table-hover .table-dark:hover {
  background-color: #babfc8;

  > {

    td,
    th {
      background-color: #babfc8;
    }
  }
}

.table-active {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    th,
    td {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    td,
    th {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table {
  .thead-dark th {
    color: $white;
    background-color: $gray-800;
    border-color: #49597b;
  }

  .thead-light th {
    color: $gray-700;
    background-color: $gray-200;
    border-color: $gray-300;
  }
}

.table-dark {
  color: $white;
  background-color: $gray-800;

  th,
  td,
  thead th {
    border-color: #49597b;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: $white-05;
  }

  &.table-hover tbody tr:hover {
    color: $white;
    background-color: $white-75;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  >.table-bordered {
    border: 0;
  }
}

@media print {
  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: $white !important;
    }
  }

  .table-bordered {

    th,
    td {
      border: 1px solid $border !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody+tbody {
      border-color: $gray-300;
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: $neutral-60;
  }
}

.table.table-clean {
  td {
    .value {
      font-size: .9rem;
      line-height: 1.6;
      font-weight: 500;
    }

    .sub-value {
      font-size: .72rem;
      color: $gray-600;
    }
  }

  tr:first-child td {
    border-top: none;
  }

  td {
    padding-left: 0px;
    padding-right: 0px;
    border-top-color: $neutral-60;
  }

  th,
  td {
    padding: 7px 20px !important;
  }
}

@media (max-width: 1199px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: $border;
}

.table-bordered>:not(caption)>* {
  border-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgb(255, 255, 255);
  color: $primary;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: $neutral-60;
  border-style: none;
  border-width: 0;
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: none !important;
  background: $bg-hover;
}


@media (min-width: 768px) {
  .table-responsive.deleted-table .data-table-btn {
    position: absolute;
    left: 185px;
    z-index: 999;
  }
}

@media (min-width: 1319px) {
  .table-responsive.export-table .dt-buttons.btn-group {
    position: absolute;
    top: 0;
    left: 172px;
  }
}

.table> :not(:first-child) {
  border-top: none !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: $white !important;
}

@media (max-width: 583px) {
  .dt-buttons {
    .btn,
    .sp-container button {
      padding: 0.5rem 0.1rem;
    }
  }
}

//* NGX CUSTOM DATATABLE STYLES
//* Referencia a la tabla personalizada
.ngx-datatable.custom-datatable {
  box-shadow: none !important;
  //* Referencias globales
  .datatable-body-row.active {
    background-color: unset !important;
    .datatable-row-group {
      background-color: unset !important;
    }
  }
  
  //* Referencias al header de la tabla
  .datatable-header {
    border-bottom: 0;
    
    .datatable-header-cell {
      background-color: $secondary-10;
      display         : flex;
      align-items     : center;
      justify-content : flex-start;
      padding         : 12px;
      
      @extend .tx-body-1-bold;
      color       : $secondary-darker;
      
      &:first-child {
        border-radius: 10px 0 0 0;
      }
      
      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
    .datatable-header-inner {
      height: 100%;
    }

    .datatable-row-right {
      display: flex;

      .datatable-header-cell {
        height     : 100%;
        display    : flex;
        align-items: center;

        .datatable-header-cell-template-wrap {
          display    : flex;
          align-items: center;
        }

        &:first-child {
          border-radius: 0;
        }
        
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
    }
  }
  
  //* Referencias a la columna
  .datatable-body {
    .datatable-body-cell {
      display        : flex;
      align-items    : center;
      justify-content: flex-start !important;
      white-space    : normal !important;
      padding        : 12px;
      
      @extend .tx-body-1;
      color: $primary;
    }
    
    .datatable-row-wrapper {
      .datatable-body-row {
        border      : 1px solid $neutral-60;
        border-top  : 0;
        border-left : 0;
        border-right: 0;
        &:hover {
          background-color: $white !important; 
        }
        &.invalid {
          background-color: $neutral-30 !important;

          .datatable-body-cell {
            color: $neutral-70 !important;
          }
        }
      }
      
      &:last-child .datatable-body-row {
        border-radius: 0 0 10px 10px;
      }
    }
    .datatable-row-even {
      background-color: $white !important;
      .datatable-row-right {
        background-color: $white !important;
      }
    }
    .datatable-row-odd {
      background-color: $neutral-20 !important;
      .datatable-row-right {
        background-color: $neutral-20 !important;
      }
    }
    
    .datatable-body-cell-label {
      display     : flex;
      align-items : flex-start;
      text-align  : start;
    }

    .datatable-row-right {
      border-left: 1px solid $primary-10;
      .datatable-body-cell {
        display        : flex;
        align-items    : center;
        justify-content: center !important;
      }
    }
  }
  
  //* Referencias al footer
  .datatable-footer {
    border  : none;
    overflow: visible;
  }
  
  //* Referencias a una checkbox table
  // &.selectable.checkbox-selection {
  //   .datatable-checkbox input[type=checkbox] {
  //     &:checked {
  //       &:before {
  //         border-color: #FFFFFF;
  //       }
  //       &:after {
  //         background: #51BFE7;
  //       }
  //     }
  //   }
  // }
}