.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.split-dropdown.dropdown-toggle {
  white-space: nowrap;

  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &:empty::after {
    margin-left: 0;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  float: left;
  min-width: 10rem !important;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: $default-color;
  text-align: left;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $border;
  border-radius: 5px !important;
  box-shadow: 0px 16px 18px rgba(135, 135, 182, .1) !important;
}


.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
    }

    &:empty::after {
      margin-left: 0;
    }

    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      display: none;
    }

    &::before {
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent;
    }

    &:empty::after {
      margin-left: 0;
    }

    &::before {
      vertical-align: 0;
    }
  }
}

.dropdown-menu {
  &[data-popper-placement^="top"], &[data-popper-placement^="right"], &[data-popper-placement^="bottom"], &[data-popper-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $gray-200;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: $gray-900;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover, &:focus {
    color: #141c2b;
    text-decoration: none;
    background-color: #fcfcff;
  }

  &.active, &:active {
    color: #141c2b;
    text-decoration: none;
    background-color: #fcfcff;
  }

  &.disabled, &:disabled {
    color: $gray-600;
    pointer-events: none;
    background-color: transparent;
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: $gray-600;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: $gray-900;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;

  &::after {
    margin-left: 0;
  }
}

.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

/* ###### 3.5 Dropdown ###### */

.dropdown-menu {
  padding: 0;
  border-width: 1px;
  border-radius: 5px;
}

.dropdown-item {
  padding: 8px 15px;
  font-size: 13px;
}

.dropdown-header {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .dropdown {
    &.d-cart .dropdown-menu, &.message .dropdown-menu {
      width: 20.5rem !important;
    }
  }
}

.user-lock .dropdown {
  position: absolute;
  right: 20px;
}

.main-dropdown-form-demo {
  .static-dropdown {
    padding: 20px;
    background-color: $gray-200;
    display: inline-flex;
    justify-content: center;

    .dropdown-menu {
      display: block;
      position: static;
      float: none;
    }
  }

  .dropdown-menu {
    padding: 20px;
  }

  .dropdown-title {
    font-size: 20px;
    font-weight: 700;
    color: $gray-900;
    margin-bottom: 5px;
  }
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .static-dropdown {
    padding: 40px;
  }
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .dropdown-menu {
    width: 300px;
    padding: 30px 25px;
  }
}


/* styles.scss o el archivo global de estilos */

@import 'src/assets/scss/variables.scss';

.disabled .ng-arrow-wrapper {
    background-color: $neutral-20 !important;
}

.disabled .ng-arrow-wrapper::after {
    color: $primary-25 !important;
}

.disabled {
    pointer-events: none;
    color: $neutral-70;
}

.disabled .ng-select-container {
    background-color: $neutral-20;
}

.ng-arrow {
    display: none !important;
}

.disabled .ng-value-container {
    color: $neutral-60;
}

.disabled .ng-clear-wrapper {
    display: none !important;
}

.ng-clear-wrapper {
    display: block;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
    color: #999 !important;
}

.ng-select .ng-clear-wrapper:hover {
    color: #999 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: $info-10 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $info-10 !important;
}

.ng-dropdown-panel-items div:first-child {
    border-top-left-radius: 5px !important;
}

.ng-dropdown-panel-items div:last-child {
    border-bottom-left-radius: 5px !important;
}

.ng-value.ng-star-inserted {
    display: flex;
    flex-direction: row-reverse;
}

.ng-clear-wrapper .ng-star-inserted {
    display: block !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
    display: block !important;
}

.arrow-icon-dropdown.ng-select-opened .ng-select-container {
    border: 1px solid $primary !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 0px !important;
}

.ng-dropdown-panel {
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    border-radius: 6px;
}

.ng-option {
    border-bottom: solid 1px $neutral-50;
    border-radius: 5px;
}

.ng-value-container .ng-input {
    padding-bottom: 0px !important;
}

.ng-placeholder {
    padding-bottom: 0px !important;
    top: 7px !important;
}

/* .arrow-icon-dropdown {
    max-width: 500px;
} */
//Fix para hacer que el atributo NG-SELECT pueda superar los 500pixeles.

.ng-arrow-wrapper {
  background-color: $neutral-30;
  padding-right: 0px !important;
  margin-right: 3px !important;
  border-radius: 8px;
  max-height: 28px;
  height: 28px;
  width: 28px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-sm .ng-arrow-wrapper {
  max-height: 22px !important;
  height: 22px !important;
  width: 22px !important
}

.ng-arrow-wrapper::after {
    content: 'expand_more';
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color: $primary-75;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    transition: transform 0.3s;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: $neutral-90 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked .ng-option-label {
    color: $neutral-90 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: $neutral-90 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: $neutral-70 !important;
}

.ng-select.ng-select-opened .ng-arrow-wrapper::after {
    transform: rotate(180deg);
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}


.ng-select.multiple .ng-option {
  display: flex !important;
  align-items: center;
  padding-left: 20px;

  input[type="checkbox"] {
      margin-right: 8px;
  }
}

@import 'src/assets/scss/variables.scss';


.ng-select .ng-option {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .ng-select.multiple .ng-option .ng-option-label {
    padding-left: 20px;
  }
  
  .ng-select.multiple .ng-option::before {
    content: "";
    display: block;
    position: absolute;
    left: 8px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .ng-select .ng-option.selected::before {
    background-color: $info-10;
  }
  
  .ng-select.multiple .ng-option input[type="checkbox"] {
    position: absolute;
    left: 8px;
    opacity: 0;
    border-radius: 3px;
    cursor: pointer;
  }

  .ng-select.multiple .ng-option[aria-selected="true"]::before {
    content: ''; 
    font-family: 'FontAwesome';
    color: #fff;
    background-color: $secondary;
    border-radius:3px ;
    font-size: 10px;
    border: 1px solid $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 55% 45%;
    background-repeat: no-repeat;
    line-height: 17px;
    width: 16px;
    height: 16px;
    background-size: 65%;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.ng-select{
  .ng-input{
    display: none;
  }
}
